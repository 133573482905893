export enum AnalyticsEventNames {
  // Global
  SEGMENT_ANALYTICS_INITIALIZED = 'Segment Analytics Initialized',
  // Buyer side
  BUYER_PREFERENCES_SUBMITTED = 'Buyer Preferences Submitted',
  BUYER_ACCESSED_SHARED_LISTING = 'Buyer Accessed Shared Listing',
  BUYER_FAILED_TO_ACCESS_SHARED_LISTING = 'Buyer Failed To Access Shared Listing',
  BUYER_ACCESSED_INBOX_LISTING = 'Buyer Accessed Inbox Listing',
  BUYER_CLICKED_INBOX_BUTTON_WITH_UNREAD_MSGS = 'Buyer Clicked Inbox Button With Unread Messages',
  BUYER_CLICKED_INBOX_BUTTON_WITHOUT_UNREAD_MSGS = 'Buyer Clicked Inbox Button Without Unread Messages',
  BUYER_CLICKED_LIVESTREAM_BANNER = 'Buyer Clicked Livestream Banner',
  LISTING_CARD_CLICKED = 'Listing Card Clicked',
  LISTINGS_FILTER_BOX_CLICKED = 'Listings Filter Box Clicked',
  LISTINGS_FILTER_BOX_SUBMITTED = 'Listings Filter Box Submitted',
  LISTING_STYLES_FILTER_BOX_CLICKED = 'Listing Styles Filter Box Clicked',
  LISTING_STYLES_FILTER_BOX_SUBMITTED = 'Listing Styles Filter Box Submitted',
  SELLER_UPLOAD_INVENTORY_CLICKED = 'Seller Upload Inventory Clicked',
  ITEM_SEARCHED = 'Item Searched',
  LISTING_FAVORITED = 'Listing Favorited',
  SIMILAR_LISTING_CARD_CLICKED = 'Similar Listing Card Clicked',
  NAV_BAR_CLICKED = 'Nav Bar Clicked',
  PAY_WITH_SLOPE_CLICKED = 'Pay With Slope Clicked',
  PAY_WITH_SLOPE_SUCCESS = 'Pay With Slope Success',
  PAY_WITH_SLOPE_CLOSE = 'Pay With Slope Close',
  PAY_WITH_SLOPE_ERROR = 'Pay With Slope Error',
  // v2
  SEARCH_LOAD_MORE_CLICKED = 'Search Load More Clicked',
  SEARCH_REQUEST_CLICKED = 'Search Request Clicked',
  SEARCH_RESULT_CLICKED = 'Search Result Clicked',
  SEARCH_FILTER_APPLIED = 'Search Filter Applied',
  SEARCH_QUERIED = 'Search Queried',
  SEARCH_SORT_CLICKED = 'Search Sort Clicked',
  SEARCH_SECTION_LISTING_VIEW = 'Search Section Listing View',
  NAV_LINK_CLICKED = 'Nav Link Clicked',
  NAV_ICON_CLICKED = 'Nav Icon Clicked',
  SIGN_IN_SUCCESS = 'Sign In Success',
  LOGOUT_CLICKED = 'Logout Clicked',
  HOME_TILE_CLICKED = 'Home Tile Clicked',
  HOME_SECTION_LISTING_VIEW = 'Home Section Listing View',
  HOME_SECTION_VIEW_ALL_CLICKED = 'Home Section View All Clicked',
  HOME_SECTION_CAROUSEL_RIGHT_ARROW_CLICKED = 'Home Section Carousel Right Arrow Clicked',
  HOME_SECTION_CAROUSEL_LEFT_ARROW_CLICKED = 'Home Section Carousel Left Arrow Clicked',
  HOME_SECTION_CAROUSEL_VIEW_MORE_CLICKED = 'Home Section Carousel View More Clicked',
  HOME_ALL_INVENTORY_VIEW_MORE_CLICKED = 'Home All Inventory View All Clicked',
  // This is used in both Home and No Results Page
  SHOP_BY_CATEGORY_CARD_CLICKED = 'Shop By Category Card Clicked',
  FILTER_APPLIED = 'Filter Applied',
  FILTER_LOAD_MORE_CLICKED = 'Filter Load More Clicked',
  LISTING_PRODUCT_CARD_CLICKED = 'Listing Product Card Clicked',
  LISTING_EXPORT_LINESHEET_CLICKED = 'Listing Export Linesheet Clicked',
  LISTING_EXPORT_LINESHEET_DOWNLOADED_VIA_URL = 'Listing Export Linesheet Downloaded Via URL',
  // Offers-2.0
  ADD_ALL_STYLES_TO_OFFER = 'Add All Styles To Offer', // From offers-1.0
  BUILD_YOUR_OFFER_CLICKED = 'Build Your Offer Clicked',
  EXPORT_OFFER_CLICKED = 'Export Offer Clicked',
  LDP_GRID_VIEW_CLICKED = 'LDP Grid View Clicked',
  LDP_HIDE_FILTERS_CLICKED = 'LDP Hide Filters Clicked',
  LDP_LIST_VIEW_CLICKED = 'LDP List View Clicked',
  LDP_PRODUCT_CLICKED = 'Style Card Clicked', // From offers-1.0
  LDP_SHOW_FILTERS_CLICKED = 'LDP Show Filters Clicked',
  OFFER_ADD_ALL_SHOWN_PRODUCTS_CLICKED = 'Offer Add All Shown Products Clicked',
  OFFER_ADD_PRODUCT_ICON_CLICKED = 'Add Style To Offer', // From offers-1.0
  OFFER_REMOVE_ALL_SHOWN_PRODUCTS_CLICKED = 'Offer Remove All Shown Products Clicked',
  OFFER_REMOVE_PRODUCT_ICON_CLICKED = 'Offer Remove Product Icon Clicked',
  OFFER_SUMMARY_LDP_LINK_CLICKED = 'Offer Summary LDP Link Clicked',
  SELECT_ALL_VARIANTS_CLICKED = 'Select All Variants Clicked',
  UNSELECT_ALL_VARIANTS_CLICKED = 'Unselect All Variants Clicked',
  PDP_ADD_TO_OFFER_CLICKED = 'PDP Add To Offer Clicked',
  ADD_ONE_VARIANT_TO_OFFER_CLICKED = 'Add One Variant To Offer Clicked',
  REMOVE_ONE_VARIANT_FROM_OFFER_CLICKED = 'Remove One Variant From Offer Clicked',
  // Negotiations-v1
  SUBMITTED_ACCEPT_OFFER_CLICKED = 'Submitted Accept Offer Clicked',
  SUBMITTED_COUNTER_OFFER_CLICKED = 'Submitted Counter Offer Clicked',
  SUBMITTED_DECLINE_OFFER_CLICKED = 'Submitted Decline Offer Clicked',
  SUBMITTED_FIRM_OFFER_CLICKED = 'Submitted Firm Offer Clicked',
  // Listing Previews
  LISTING_PREVIEW_ACCESS_REQUEST_CLICKED = 'Listing Preview Access Request Clicked',
  // Partial Listings
  PARTIAL_LISTING_EXPORT_CLICKED = 'Partial Listing Export Clicked',
  // BOM = Build Offer Modal
  DELETE_OFFER_CLICKED = 'Delete Offer Clicked',
  RESET_OFFER_CLICKED = 'Reset Offer Clicked',
  LOW_BALL_OFFER_SUBMITTED = 'Low Ball Offer Submitted',
  OFFER_CONFIRMATION_SUBMIT_CLICKED = 'Offer Confirmation Submit Clicked', // From offers-1.0
  OFFER_UPDATE_TOTAL_OFFER_PRICE_CLICKED = 'Offer Update Total Offer Price Clicked',
  OFFER_UPDATE_PERCENT_OFF_MSRP_CLICKED = 'Offer Update Percent Off MSRP Clicked',
  OFFER_ADD_MORE_ITEMS_CLICKED = 'Offer Add More Items Clicked',
  OFFER_DELETE_ONE_PRODUCT_CLICKED = 'Offer Delete One Product Clicked',
  OFFER_UNDO_PRODUCT_DELETION_CLICKED = 'Offer Undo Product Deletion Clicked',
  // 3rd Party Data
  PRODUCT_VARIANT_RESEARCH_CARD_AMAZON_PRODUCT_LINK_CLICKED = 'Product Variant Research Card Amazon Product Link Clicked',
  PRODUCT_VARIANT_RESEARCH_CARD_SELECT_CLICKED = 'Product Variant Research Card Select Clicked',
  PRODUCT_VARIANT_RESEARCH_CARD_SELECT_CHANGED = 'Product Variant Research Card Select Changed',
  PRODUCT_VARIANT_RESEARCH_CARD_ASIN_COPIED = 'Product Variant Research Card ASIN Copied',
  PRODUCT_VARIANT_RESEARCH_CARD_UPC_COPIED = 'Product Variant Research Card UPC Copied',
  // Seller Sign Up
  SELLER_SIGNUP_INITIATED = 'Seller Sign Up Initiated',
  SELLER_SIGNUP_STEP_BACKTRACKED = 'Seller Sign Up Backtracked',
  SELLER_SIGNUP_STEP_COMPLETED = 'Seller Sign Up Step Completed',
  SELLER_SIGNUP_SUCCESS = 'Seller Sign Up Success ',
  // Seller Dashboard
  SELLER_NEEDS_REVIEW_VIEWED = 'Seller Needs Review Viewed',
  SELLER_PENDING_VIEWED = 'Seller Pending Viewed',
  SELLER_COMPLETED_VIEWED = 'Seller Completed Viewed',
  SELLER_ARCHIVED_VIEWED = 'Seller Archived Viewed',
  SELLER_REVIEW_OFFER_CLICKED = 'Seller Review Offer Clicked',
  SELLER_CONTACT_GHOST_CLICKED = 'Seller Contact Ghost Clicked',
  SELLER_INVENTORY_PAGE_VIEWED = 'Seller Inventory Page Viewed',
  SELLER_INVENTORY_UPLOADED = 'Seller Inventory Uploaded',
  SELLER_LOT_EXPORTED = 'Seller Lot Exported',
  // Seller Offer Details
  SELLER_OPENED_ACCEPT_OFFER_DIALOG = 'Seller Opened Accept Offer Dialog',
  SELLER_ABANDONED_ACCEPTANCE_WORKFLOW = 'Seller Abandoned Acceptance Workflow',
  SELLER_COMPLETED_ACCEPTANCE_WORKFLOW = 'Seller Completed Acceptance Workflow',
  SELLER_SUBMITTED_OFFER_ACCEPTANCE_FOR_REVIEW = 'Seller Submitted Offer Acceptance For Review',
  SELLER_BEGAN_COUNTER_OFFER_WORKFLOW = 'Seller Began Counter Offer Workflow',
  SELLER_ABANDONED_COUNTER_OFFER_WORKFLOW = 'Seller Abandoned Counter Offer Workflow',
  SELLER_SAVED_COUNTER_OFFER = 'Seller Saved Counter Offer',
  SELLER_ABANDONED_COUNTER_OFFER_CONFIRMATION_WORKFLOW = 'Seller Abandoned Counter Offer Confirmation Workflow',
  SELLER_COMPLETED_COUNTER_OFFER_CONFIRMATION_WORKFLOW = 'Seller Completed Counter Offer Confirmation Workflow',
  SELLER_SUBMITTED_COUNTER_OFFER_FOR_REVIEW = 'Seller Submitted Counter Offer For Review',
  // Buyer Offer Details
  BUYER_OPENED_ACCEPT_OFFER_DIALOG = 'Buyer Opened Accept Offer Dialog',
  BUYER_ABANDONED_ACCEPTANCE_WORKFLOW = 'Buyer Abandoned Acceptance Workflow',
  BUYER_COMPLETED_ACCEPTANCE_WORKFLOW = 'Buyer Completed Acceptance Workflow',
  BUYER_OPENED_DECLINE_OFFER_DIALOG = 'Buyer Opened Decline Offer Dialog',
  BUYER_ABANDONED_DECLINE_OFFER_WORKFLOW = 'Buyer Abandoned Decline Offer Workflow',
  BUYER_COMPLETED_DECLINE_OFFER_WORKFLOW = 'Buyer Completed Decline Offer Workflow',
  BUYER_SAVED_COUNTER_OFFER = 'Buyer Saved Counter Offer',
  BUYER_ABANDONED_COUNTER_OFFER_CONFIRMATION_WORKFLOW = 'Buyer Abandoned Counter Offer Confirmation Workflow',
  BUYER_COMPLETED_COUNTER_OFFER_CONFIRMATION_WORKFLOW = 'Buyer Completed Counter Offer Confirmation Workflow',
  BUYER_BEGAN_COUNTER_OFFER_WORKFLOW = 'Buyer Began Counter Offer Workflow',
  BUYER_ABANDONED_COUNTER_OFFER_WORKFLOW = 'Buyer Abandoned Counter Offer Workflow',
  // Buyer Preferences Modal
  BUYER_PREFERENCES_MODAL_VIEWED = 'Buyer Preferences Modal Viewed',
  BUYER_PREFERENCES_MODAL_DISMISSED = 'Buyer Preferences Modal Dismissed',
  BUYER_PREFERENCES_MODAL_NEXT = 'Buyer Preferences Modal Clicked Next',
  BUYER_PREFERENCES_MODAL_BACK = 'Buyer Preferences Modal Clicked Back',
  // Payments
  BUYER_OPENED_PAYMENT_MODAL = 'Buyer Opened Payment Modal',
  BUYER_ABANDONED_PAYMENT_MODAL = 'Buyer Abandoned Payment Modal',
  BUYER_INITIATED_ADD_PAYMENT_METHOD = 'Buyer Initiated Add Payment Method',
  BUYER_ADDED_PAYMENT_METHOD = 'Buyer Successfully Added Payment Method',
  BUYER_ERROR_WHEN_ADDING_PAYMENT_METHOD = 'Buyer Error When Adding Payment Method',
  BUYER_ABANDONED_ADDING_PAYMENT_METHOD = 'Buyer Abandoned Adding Payment Method',
  BUYER_INITIATED_CONFIRM_PAYMENT = 'Buyer Initiated Confirm Payment',
  BUYER_SELECTED_PREFERRED_PAYMENT_METHOD = 'Buyer Selected Preferred Payment Method',
  PAYMENT_SUBMISSION_SUCCEEDED = 'Payment Submission Succeeded',
  PAYMENT_SUBMISSION_PROCESSING_INITIATED = 'Payment Submission Processing Initiated',
  PAYMENT_SUBMISSION_FAILED = 'Payment Submission Failed',
  PAYMENT_SUBMISSION_STATUS_UNKNOWN = 'Payment Submission Type Unknown',

  // Seller Inventory
  SELLER_INVENTORY_TAB_CLICKED = 'Seller Inventory Tab Clicked',
  SELLER_INVENTORY_UPLOAD_INVENTORY_CLICKED = 'Seller Inventory Upload Inventory Clicked',
  SELLER_INVENTORY_CARD_CLICKED = 'Seller Inventory Card Clicked',
  SELLER_INVENTORY_SEARCH_SUBMITTED = 'Seller Inventory Search Submitted',
  SELLER_INVENTORY_PAGINATION_FORWARD_CLICKED = 'Seller Inventory Pagination Forward Clicked',
  SELLER_INVENTORY_PAGINATION_BACK_CLICKED = 'Seller Inventory Pagination Back Clicked',
  SELLER_INVENTORY_SEARCH_BOX_CLEAR_CLICKED = 'Seller Inventory Search Box Clear Clicked',
  SELLER_INVENTORY_HAMBURGER_MENU_CLICKED = 'Seller Inventory Hamburger Menu Clicked',
  SELLER_INVENTORY_CATEGORY_FILTER_CHANGED = 'Seller Inventory Category Filter Changed',
  SELLER_INVENTORY_BRAND_FILTER_CHANGED = 'Seller Inventory Brand Filter Changed',
  SELLER_INVENTORY_SORT_CHANGED = 'Seller Inventory Sort Changed',

  // Seller Dashboard
  SELLER_DASHBOARD_METRICS_CHART_LEGEND_CLICKED = 'Selled Dashboard Metrics Chart Legend Clicked',

  // Buyer Deals Page
  BUYER_VIEWED_COUNTER_OFFER = 'Buyer Viewed Counter Offer',
  BUYER_FILTERED_DEALS = 'Buyer Filtered Deals',
  BUYER_CLEARED_DEALS_FILTER = 'Buyer Cleared Deals Filter',
  BUYER_CLICKED_MY_DEALS_MENU_ITEM = 'Buyer Clicked My Deals Menu Item',
}
